import { IConversationSummary } from 'store/contacts/reducer'
import { useDispatch, useSelector } from 'util/hooks'
import { getInstitutionTimeZone } from 'store/triage/profile/selectors'
import moment from 'moment-timezone'
import { AIAction, AIMenu, MenuSize } from 'components/AIMenu/AIMenu'
import { generateConversationSummaryAsync } from 'store/contacts/thunks'
import { getGeneratingSummary } from 'store/contacts/selectors'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'

export function ConversationSummary({
  conversationSummary,
  contactId,
}: {
  conversationSummary: IConversationSummary
  contactId: string
}) {
  const dispatch = useDispatch()
  const loading = useSelector(getGeneratingSummary)

  const timeZone = useSelector(getInstitutionTimeZone)
  const summaryTimestamp = conversationSummary.timestamp
    ? moment.tz(conversationSummary.timestamp, timeZone)
    : null

  return (
    <RefetchingOverlay enabled={loading} centeredSpinner>
      <AIMenu
        size={MenuSize.WIDE}
        actions={[
          {
            action: AIAction.CONVERSATION_SUMMARY,
            onClick: () => {
              generateConversationSummaryAsync(dispatch)(contactId)
            },
            text: 'Generate Summary',
          },
        ]}
        eventTrackerObj={{
          eventLocation: 'conversations',
          eventAction: 'click',
          eventObject: 'generate conversation summary',
        }}
      />
      <h5 className="mt-2 mb-0">
        {summaryTimestamp && summaryTimestamp.format('MM/DD/YYYY, hh:mmA z')}
      </h5>
      {conversationSummary.summary}
    </RefetchingOverlay>
  )
}
